
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

@media (min-width: 1024px) {
    .listing-item {
        &:hover {
            -webkit-box-shadow: 2px 2px 22px 1px rgba(0, 0, 0, 0.507);
            box-shadow: 2px 2px 22px 1px rgba(0, 0, 0, 0.356);
            transform: scale(1.03);
        }
    }
}
.listing-item {
    background-color: white;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    transition: all 350ms ease-in-out;
    &:hover {
        .button {
            &:before {
                transform: skewX(-45deg) translateX(16em);
                transition: all .6s ease-in-out;
            }
            transition: all 0.3s ease-in-out;
            filter: brightness(90%);
            svg {
                margin-left: 1rem;
                transition: all 0.3s ease-in-out;
            }
        }
    }
}
@media (min-width: 768px) {
    .listing-item {
        margin-left: 0;
        margin-right: 0;
        border: 2px solid $antivirus-choice-gray-light;
    }
}
.yellow {
    background-color: $website-builder-yellow;
}
.gray {
    background-color: $website-builder-gray;
}
.button {
    overflow: hidden;
    color: white;
    background: $website-builder-orange;
    position: relative;

    &:before {
        content: "";
        background-color: rgba(255, 255, 255, 0.5);
        height: 100%;
        width: 3em;
        display: block;
        position: absolute;
        top: 0;
        left: -4.5em;
        transform: skewX(-45deg) translateX(-130%);
        transition: none;
    }
}

@media (min-width: 768px) {
    .shift:first-child {
        margin-top: -40px;
    }
}
@media (min-width: 1024px) {
    .shift:first-child {
        margin-top: -80px;
    }
}
