
            @import 'src/styles/mixins.scss';
        
.listing{
    margin: 0 -3vmin;
    position: relative;
    &::before{
        content : "";
        position: absolute;
        z-index: -1;
        left: 50%;
        top: 50%;
        height:calc(100% + 2px);
        width: 100vw;
        transform: translate(-50%, -50%);
        background-color: #f9f9f9
    }
    
}
@media (min-width: 1024px) {
    .listing{
        margin: 0;
    }
}